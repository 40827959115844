import axios from 'axios';
// import authFunc from '../utils/authFunc';
// import tableFunc from '../utils/tableFunc';
// import globalAlert from '../utils/globalAlert'

let baseURL = ""
// const alert = (msg, type = 'error', defaultMsg = '页面异常，请稍后重试') => {
//     globalAlert(msg || defaultMsg, type)
// }

console.log('process.env = ', process.env)

if (process.env.NODE_ENV == 'development') {
    baseURL = process.env.VUE_APP_DEV_URL
} else if (process.env.NODE_ENV == 'test') {
    baseURL = process.env.VUE_APP_TEST_URL
} else {
    baseURL = process.env.VUE_APP_PRODUCT_URL
}

console.log('baseURL = ', baseURL)

//创建axios实例
const service = axios.create({
    baseURL: baseURL, // api 的 base_url
    timeout: 60 * 1000, // 请求超时时间
    withCredentials: false
});

const post = (url, param, headers) => {
    if (headers) {
        return service.post(url, param, headers)
    } else {
        return service.post(url, param)
    }
}

const get = (url, param) => {
    return service.get(url, { params: param })
}

const put = (url, param) => {
    return service.put(url, param)
}

const deleteFunc = (url, param) => {
    return service.delete(url, param)
}

// const downloadFile = async (url,fileName) => {
//     let res = await service.get(url,{responseType: 'blob'})
//     let blob = new Blob([res.data])
//     tableFunc.downloadTable(blob,fileName)
// }

service.interceptors.request.use((config) => {
    // console.log('request config = ', config)
    if(window.localStorage.getItem('access_token')){
        const Authorization = 'Bearer '+window.localStorage.getItem('access_token')
        config.headers['Authorization'] = Authorization
    }
    return config
}, (err) => {
    console.log('request err = ', err)
    return Promise.reject(err)
})

service.interceptors.response.use((response) => {
    console.log('response response = ', response)
    let res = handleResponseData(response)
    return res
}, (err) => {
    console.log('response err = ', err.response)
    if (err && err.response) {
        if (err.response.data &&  err.response.data.msg) {
            err.message = err.response.data.msg
        } else {
            switch (err.response.status) {
                case 400:
                    err.message = '请求错误'
                    break
                case 401:
                    err.message = '未授权，请重新登录'
                    break
                case 403:
                    err.message = '禁止访问'
                    break
                case 404:
                    err.message = '请求的内容未找到或已删除'
                    break
                case 405:
                    err.message = '请求方法未允许'
                    break
                case 408:
                    err.message = '请求超时'
                    break
                case 413:
                    err.message = '上传文件过大'
                    break
                case 500:
                    err.message = '服务器端程序错误'
                    break
                case 501:
                    err.message = '服务器不支持的请求方法'
                    break
                case 502:
                    err.message = '网关无响应'
                    break
                case 503:
                    err.message = '服务器端临时错误'
                    break
                case 504:
                    err.message = '网关超时'
                    break
                case 505:
                    err.message = '服务器不支持的HTTP版本'
                    break
                default:
                    err.message = err.response.msg || '请求出错，请稍后重试'
            }
            err.message = `【${err.response.status}】${err.message}`
        }
    } else {
        err.message = '当前网络状态不佳，无法请求接口'
    }
    alert(err.message)
    return Promise.reject(err)
})

const handleResponseData = (response) => {
    if (response.status == 200) {
        if (response.data.code == 0||response.data.code == 200) {
            return response.data
        } else if (response.data.code == 7) {
            // alert(response.data.msg)
            return response.data
        } else {
            console.log('Not required response')
            return response
        }
    }
    // else if(response.status == )
}

export default {
    service,
    post,
    get,
    put,
    deleteFunc,
    // downloadFile
}