import http from "./http";

//获取图片验证码
const queryCaptcha = async () => {
    let res = await http.get('/api/queryCaptcha')
    console.log('queryCaptcha res = ',res);

    return res
}

const queryCaptchaMo = async () => {
    let res = await http.get('/api/queryCaptchaMo')
    console.log('queryCaptcha res = ',res);
    return res
}
const verifyCaptchaMo = async (param) => {
    let res = await http.get('/api/verifyCaptchaMo',param)
    console.log('queryCaptcha res = ',res);
    return res
}

//获取用户信息
const getUserInfoByCode = async (param) => {
    let res = await http.get('/api/getThirdUserInfo',param)
    console.log('getUserInfoByCode res = ',res);
    return res
}

//获取二维码跳转地址
const qrScanRedireact = async () => {
    let redicreactUrl=process.env.VUE_APP_PRODUCT_URL;
    let res = await http.get(`/api/getRedireactUrl?RUrl=${redicreactUrl}`)
    console.log('queryCaptcha res = ',res);

    return res
}

//登录接口
const loginFunc = async (param) => {
    let res = await http.post('/api/login',param)
    console.log('loginFunc res = ',res)

    return res
}

//获取邮件验证码
const queryVerifyMail = async (param) => {
    const res = await http.get('/api/queryMailVerifyCode',param)
    console.log('queryVerifyMail res = ',res);

    return res
}

//注册接口
const registerFunc = async (param) => {
    const res = await http.post('/api/registerUser',param)

    return res
}

//修改密码
const modifyPassword = async (param) => {
    const res = await http.post('/api/modifyPassword',param)

    return res
}
const bandUserUUId = async (param) => {
    const res = await http.post('/api/bandUserUUId',param)
    return res
}

export default {
    queryCaptcha,
    loginFunc,
    queryVerifyMail,
    registerFunc,
    modifyPassword,
    qrScanRedireact,
    getUserInfoByCode,
    bandUserUUId,
    queryCaptchaMo,
    verifyCaptchaMo,
}